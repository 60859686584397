import qs from "qs";
import { IUserDto } from "../typing/loginTyping";
import { IUser } from "../typing/mgtTyping";
import { IUserEamilDto, IUserPasswordDto, IUserPhoneDto,ICompany, IETaxDetailQueryDto, IETaxDetail } from "../typing/selfTyping";
import requests from "./request";

export async function getCurrent() {
  return requests.get<IUserDto>('/user/current')
}

export async function getUserInfoById(id: string) {
  return requests.get<IUser>(`/user/detail/${id}`)
}

export async function changePassword(data: IUserPasswordDto) {
  return requests.put<null>(`/user/update/password`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function changeEmail(data: IUserEamilDto) {
  return requests.put<null>(`/user/email`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
export async function changePhone(data:IUserPhoneDto) {
  return requests.put<null>(`/user/phone`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  
}

export async function followArticle(articleId: string) {
  return requests.post<null>(`/user/follow/article/${articleId}`)
}

export async function deleteFollowArticle(articleId: string) {
  return requests.delete<null>(`/user/follow/article/${articleId}`)
}

export async function getCompanyPage(params?: ICompany) {
  return requests.getPage<ICompany>(`/user/setting/company?${qs.stringify(params)}`)
}

export async function addCompany(data: ICompany) {
  return requests.post<ICompany>(`/user/setting/company`, data ,{
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function updateCompany(data: ICompany, id: string) {
  return requests.put<ICompany>(`/user/setting/company/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function deleteCompany(id: string) {
  return requests.delete<null>(`/user/setting/company/${id}`)
}

export async function getETaxDetailPage(params?: IETaxDetailQueryDto) {
  return requests.getPage<IETaxDetail>(`/user/vat/history?${qs.stringify(params)}`, {
    headers: {
      'Accept-Language': 'en',
    },
  })
}