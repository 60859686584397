import { IPhoneSendCodeParams } from "../typing/loginTyping";
import requests from "./request";

export async function login(params: API.ILoginRequset) {
  return requests.post<API.ILoginResponse>('/login', params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function sendLoginEmailCode(email: string) {
  return requests.get<null>(`/login/loginCode/${email}`)
}

export async function sendLoginPhoneCode(phone: string) {
  return requests.get<null>(`/login/loginCode/${phone}`)
}

export async function logout() {
  return requests.post<null>('/login/logout')
}

export async function sendEmailCode(params: API.IPasswordCodeDto) {
  return requests.post<null>('/password/send/code', params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function sendRegisterEmailCode(email: string) {
  return requests.get<null>(`/user/registerCode/${email}`)
}

export async function sendupdatePhoneCode(params: IPhoneSendCodeParams) {
  return requests.post<null>(`/user/update/sendCode`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function sendRegisterPhoneCode(params: IPhoneSendCodeParams) {
  return requests.post<null>(`/user/phone/sendCode`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function register(params: API.IRegisterDto) {
  return requests.post<API.ILoginResponse>('/user/register', params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function passwordReset(params: API.IPasswordResetDto) {
  return requests.post<null>('/password/reset', params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function getImageVerifyCode(key: string) {
  return requests.get<API.IImageVerifyCode>(`/verify/get/vrcode?key=${key}`)
}

export async function verifyCode(key: string, moveLength: number) {
  return requests.post<null>(`/verify/check/vrcode?key=${key}&moveLength=${moveLength}`)
}