import { Carousel, Modal } from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { ICarousel } from "../../../typing/mgtTyping"
import { Link } from "gatsby"
import type { CATEGORY_MODULE } from "../../../typing/enum";
import { getCarouselShow } from "../../../service/mgtService";

interface IProps {
  model?: CATEGORY_MODULE | 'HOME';
}

const CarouselComponent: React.FC<IProps> = ({ model }) => {
  const [carousels, setCarousels] = useState<ICarousel[]>([])

  const getCarouselsHandler = useCallback(async () => {
    if (model) {
      const res = await getCarouselShow({ model })
      if (res.code === 200 && res.data) {
        setCarousels(res.data)
      } else if (res.code === 500) {
        Modal.error({
          title: 'ERROR',
          content: res.msg || 'Unknown exception',
        });
      }
    }
  }, [model])

  useEffect(() => {
    getCarouselsHandler()
  }, [getCarouselsHandler])


  const imgs = useMemo(() => carousels.map(item => {
    if (item.redirectUrl) {
      return <Link to={item.redirectUrl}>
        <img src={item.imageUrl} />
      </Link>
    }
    return <img src={item.imageUrl} />
  }), [carousels])

  return carousels.length ? <Carousel autoplay adaptiveHeight autoplaySpeed={12000} arrows >
    {imgs}
  </Carousel> : null
}

export default CarouselComponent
