import React, { useCallback, useEffect, useMemo } from "react"
import Header from "./Header"
import './index.less'
import { useAppDispatch, useAppSelector } from "../hooks/redusHooks";
import { selectUserBaseInfo } from "../store/selector/user.selector";
import { setUserBaseInfo, setUserPermission } from "../store/reducers/user.reducer";
import { Modal } from "antd";
import { getCurrent } from "../service/userService";
import Footer from "./Footer";
import Home from "./home";
import Admin from "./admin";
import SelfSetting from "./selfSetting"
import { PageProps } from "gatsby";
import WebSetting from "./webSetting";
import { CATEGORY_MODULE } from "../typing/enum";
import { setCurrentPermission } from "../util/commonUtil";

interface IProps {
  children: React.ReactNode,
  path: string,
}

const BaseLayouts: React.FC<IProps & PageProps<object, object, { model?: CATEGORY_MODULE }>> = (props) => {
  const { children, path, location } = props
  const model = location.state?.model;
  const dispath = useAppDispatch()
  const userBaseInfo = useAppSelector(selectUserBaseInfo)

  const getCurrentHandler = useCallback(async () => {
    // 获取用户信息
    const res = await getCurrent();
    if (res.code === 200 && res.data) {
      dispath(setUserBaseInfo(res.data));
      if (!res.data) {
        setCurrentPermission([])
        dispath(setUserPermission([]))
      }
    } 
    // else if (res.code === 500) {
      // dispath(setUserBaseInfo(null));
    //   Modal.error({
    //     title: 'ERROR',
    //     content: res.msg || 'Unknown exception',
    //   });
    // }
  }, [])

  useEffect(() => {
    if (!userBaseInfo) {
      getCurrentHandler()
    }
  }, [userBaseInfo, getCurrentHandler])

  const { isLoginPage, isAdminMgt, isSelfSetting, isWebSetting } = useMemo(() => ({
    isLoginPage: path.startsWith('/login') || path.startsWith('/register'),
    isAdminMgt: path.startsWith('/admin'),
    isSelfSetting: path.startsWith('/user/setting'),
    isWebSetting: path === '/setting' || path === '/setting/'
  }), [path])

  const MainView = useMemo(() => {
    if (isAdminMgt) {
      return <Admin children={children} path={path} />
    }
    if (isSelfSetting) {
      return <SelfSetting children={children} path={path} />
    }
    if (isWebSetting) {
      return <WebSetting children={children} path={path} />
    }
    return <Home children={children} path={path} model={model} />
  }, [isAdminMgt, isSelfSetting, isWebSetting, path, children])

  // if (!userBaseInfo) {
  //   return <div></div>
  // }

  return <div>
    <div>
      {!isLoginPage && <Header path={path} model={model} />}
    </div>
    {MainView}
    <Footer />
  </div>
}

export default BaseLayouts