import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IPermission } from "../../typing/mgtTyping";
import { appSlice } from "./app.reducer";

// 为 slice state 定义一个类型
interface RoleState {
  allPermission: IPermission[];
}

const initialState: RoleState = {
  allPermission: [],
}

export const roleSlice = createSlice({
  name: 'role',
  // `createSlice` 将从 `initialState` 参数推断 state 类型
  initialState,
  reducers: {
    setAllPermission: (state, action: PayloadAction<IPermission[]>) => {
      state.allPermission = action.payload
    },
  }
})

export const { setAllPermission } = roleSlice.actions

export default roleSlice.reducer