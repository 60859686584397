import { Tag } from "antd"
import Category from "../layouts/home/Category"
import { release } from "os"
import Feedback from "react-bootstrap/esm/Feedback"

export const permissionMap: Record<string, Record<string, string>> = {
  admin: {
    access: 'admin.access'
  },
  comment: {
    add: 'comment.add',
    edit: 'comment.edit',
    delete: 'comment.delete',
    log: 'comment.log',
  },
  article: {
    add: 'article.add',
    edit: 'article.edit',
    delete: 'article.delete',
    log: 'article.log',
  },
  auditLog: {
    add: 'auditLog.add'
  },
  annotations: {
    add: 'annotations.add',
    delete: 'annotations.delete',
    query: 'annotations.query',
    edit: 'annotations.edit',
  },
  tag: {
    add: 'tag.add',
    edit: 'tag.edit',
    delete: 'tag.delete',
  },
  category: {
    add: 'category.add',
    edit: 'category.edit',
    delete: 'category.delete',
    query: 'category.query',
    admin: 'category.admin'
  },
  user: {
    query: 'user.query',
    add: 'user.add',
    edit: 'user.edit',
    delete: 'user.delete',
    status: 'user.status',
  },
  role: {
    query: 'role.query',
    add: 'role.add',
    edit: 'role.edit',
    delete: 'role.delete',
    permission: 'role.permission',
  },
  feedback: {
    create: 'feedback.comment.create',
    delete: 'feedback.comment.delete',
    update: 'feedback.comment.update'
  },
  agent: {
    add: 'agent.add',
    delete: 'agent.delete',
    edit: 'agent.edit',
    query: 'agent.query'
  },
  announcement: {
    add: 'announcement.add',
    delete: 'announcement.delete',
    update: 'announcement.update',
    page: 'announcement.page'
  },
  question: {
    add: 'question.add',
    delete: 'question.delete',
    update: 'question.update',
    page: 'question.page'
  },
  answer: {
    add: 'answer.add',
    delete: 'answer.delete',
    update: 'answer.update',
  },
  news: {
    add: 'news.add',
    delete: 'news.delete',
    update: 'news.update',
  },
  cover: {
    add: 'cover.add',
    delete: 'cover.delete',
    update: 'cover.update',
  },
  carousel: {
    add: 'carousel.add',
    delete: 'carousel.delete',
    update: 'carousel.update',
  }
}

export const ROLE_MAP = {
  /**
   * 超级管理员
   */
  ADMIN: 'Admin',
  /**
   * 版主
   */
  MODERATOR: 'Moderator',
  /**
   * 普通用户
   */
  USER: 'User',
  /**
   * 游客
   */
  Tourist: 'Tourist',
}

export const PERMISSION_NAME_MAP: Record<string, string> = {
  announcement: '萃欧公告权限',
  article: '知识库权限',
  news: '资讯权限',
  question: 'Q&A权限',
  comment: '评论权限',
  answer: '回答权限',
  tag: '标签权限',
  category: '分类权限',
  user: '用户权限',
  role: '角色权限',
  feedback: '反馈权限',
  annotations: '批注权限',
  cover: '封面权限',
  carousel: '轮播图权限'
}