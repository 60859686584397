import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers/root.reducer'

const store = configureStore({
  reducer: rootReducer
})

export default store;

// 从 store 本身推断出 `RootState` 和 `AppDispatch` 类型
export type RootState = ReturnType<typeof store.getState>
// 推断出类型: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch