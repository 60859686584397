import {createSlice } from "@reduxjs/toolkit"

// 为 slice state 定义一个类型
interface AppState {
  appName: string;
}

const initialState: AppState = {
  appName: '萃欧知识库'
}

export const appSlice = createSlice({
  name: 'app',
  // `createSlice` 将从 `initialState` 参数推断 state 类型
  initialState,
  reducers: {}
})

export const {  } = appSlice.actions

export default appSlice.reducer