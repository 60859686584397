import React, { FC, memo } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import AccordionNav from '../../components/Mgt/AccordionNav';
import './index.less';
import { SELF_MENUS } from '../../config/configuration';

interface IProps {
  children: React.ReactNode;
  path: string;
}

const SelfSetting: FC<IProps> = ({ children, path}) => {
  const menus = cloneDeep(SELF_MENUS);

  return (
    <>
      <div className="bg-light py-2">
        <Container className="py-1">
          <h6 className="mb-0 fw-bold lh-base">
            个人资料
          </h6>
        </Container>
      </div>
      <Container className="admin-container">
        <Row>
          <Col lg={2}>
            <AccordionNav menus={SELF_MENUS} path={path} />
          </Col>
          <Col lg={10} style={{ position: 'static' }}>
            {children}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(SelfSetting);
