import React, { FC, memo } from 'react';
import { Container } from 'react-bootstrap';
import './index.less';

interface IProps {
  children: React.ReactNode;
  path: string;
}

const WebSetting: FC<IProps> = ({ children, path}) => {

  return (
    <>
      <div className="bg-light py-2">
        <Container className="py-1">
          <h6 className="mb-0 fw-bold lh-base">
            设置
          </h6>
        </Container>
      </div>
      <Container className="admin-container">
        {children}
      </Container>
    </>
  );
};

export default memo(WebSetting);
