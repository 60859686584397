import { combineReducers } from '@reduxjs/toolkit'
import simpleDemoReducer from './simpleDemo.reducer'
import userReducer from './user.reducer'
import appReducer from './app.reducer'
import homeReducer from './home.reducer'
import articleReducer from './article.reducer'
import roleReducer from './role.reducer'
import companySlice from './companySlice'

export default combineReducers({
  simpleDemoReducer,
  userReducer,
  appReducer,
  homeReducer,
  articleReducer,
  roleReducer,
  companySlice
})