import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import AccordionNav from '../../components/Mgt/AccordionNav';
import './index.less';
import { ADMIN_NAV_MENUS } from '../../config/configuration';

interface IProps {
  children: React.ReactNode;
  path: string;
}

const Index: FC<IProps> = ({ children, path }) => {

  const menus = cloneDeep(ADMIN_NAV_MENUS);

  return (
    <>
      <div className="bg-light py-2">
        <Container className="py-1">
          <h6 className="mb-0 fw-bold lh-base">
            后台管理
          </h6>
        </Container>
      </div>
      <Container className="admin-container">
        <Row>
          <Col lg={2}>
            <AccordionNav menus={menus} path={path} />
          </Col>
          <Col lg={10} style={{ position: 'static' }}>
            {children}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
