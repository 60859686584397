import { ROLE_MAP } from "../../config/permissionContant";
import { getCurrentPermission } from "../../util/commonUtil";
import { RootState } from "../createStore";

// 选择器等其他代码可以使用导入的 `RootState` 类型
export const selectUserBaseInfo = (state: RootState) => state.userReducer.userBaseInfo

export const selectUserPermission = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  if (userPermission.length > 0) {
    return userPermission
  }
  return getCurrentPermission()
}
/**
 * 是否为管理员
 */
export const selectUserIsAdmin = (state: RootState) => {
  const userInfo = state.userReducer.userBaseInfo
  return userInfo?.roleCode === ROLE_MAP.ADMIN
}
/**
 * 是否为版主
 */
export const selectUserIsModerator = (state: RootState) => {
  const userInfo = state.userReducer.userBaseInfo
  return userInfo?.roleCode === ROLE_MAP.MODERATOR
}

/**
 * 是否为普通用户
 */
export const selectUserIsUser = (state: RootState) => {
  const userInfo = state.userReducer.userBaseInfo
  return userInfo?.roleCode === ROLE_MAP.USER
}