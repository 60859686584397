exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-agent-index-tsx": () => import("./../../../src/pages/admin/agent/index.tsx" /* webpackChunkName: "component---src-pages-admin-agent-index-tsx" */),
  "component---src-pages-admin-announcement-index-tsx": () => import("./../../../src/pages/admin/announcement/index.tsx" /* webpackChunkName: "component---src-pages-admin-announcement-index-tsx" */),
  "component---src-pages-admin-article-index-tsx": () => import("./../../../src/pages/admin/article/index.tsx" /* webpackChunkName: "component---src-pages-admin-article-index-tsx" */),
  "component---src-pages-admin-carousel-index-tsx": () => import("./../../../src/pages/admin/carousel/index.tsx" /* webpackChunkName: "component---src-pages-admin-carousel-index-tsx" */),
  "component---src-pages-admin-category-index-tsx": () => import("./../../../src/pages/admin/category/index.tsx" /* webpackChunkName: "component---src-pages-admin-category-index-tsx" */),
  "component---src-pages-admin-comment-index-tsx": () => import("./../../../src/pages/admin/comment/index.tsx" /* webpackChunkName: "component---src-pages-admin-comment-index-tsx" */),
  "component---src-pages-admin-cover-index-tsx": () => import("./../../../src/pages/admin/cover/index.tsx" /* webpackChunkName: "component---src-pages-admin-cover-index-tsx" */),
  "component---src-pages-admin-feedback-index-tsx": () => import("./../../../src/pages/admin/feedback/index.tsx" /* webpackChunkName: "component---src-pages-admin-feedback-index-tsx" */),
  "component---src-pages-admin-moderator-index-tsx": () => import("./../../../src/pages/admin/moderator/index.tsx" /* webpackChunkName: "component---src-pages-admin-moderator-index-tsx" */),
  "component---src-pages-admin-news-index-tsx": () => import("./../../../src/pages/admin/news/index.tsx" /* webpackChunkName: "component---src-pages-admin-news-index-tsx" */),
  "component---src-pages-admin-permission-index-tsx": () => import("./../../../src/pages/admin/permission/index.tsx" /* webpackChunkName: "component---src-pages-admin-permission-index-tsx" */),
  "component---src-pages-admin-question-index-tsx": () => import("./../../../src/pages/admin/question/index.tsx" /* webpackChunkName: "component---src-pages-admin-question-index-tsx" */),
  "component---src-pages-admin-tag-index-tsx": () => import("./../../../src/pages/admin/tag/index.tsx" /* webpackChunkName: "component---src-pages-admin-tag-index-tsx" */),
  "component---src-pages-admin-user-index-tsx": () => import("./../../../src/pages/admin/user/index.tsx" /* webpackChunkName: "component---src-pages-admin-user-index-tsx" */),
  "component---src-pages-article-article-2-tsx": () => import("./../../../src/pages/article/Article2.tsx" /* webpackChunkName: "component---src-pages-article-article-2-tsx" */),
  "component---src-pages-article-article-meta-tsx": () => import("./../../../src/pages/article/ArticleMeta.tsx" /* webpackChunkName: "component---src-pages-article-article-meta-tsx" */),
  "component---src-pages-article-index-tsx": () => import("./../../../src/pages/article/index.tsx" /* webpackChunkName: "component---src-pages-article-index-tsx" */),
  "component---src-pages-editor-index-tsx": () => import("./../../../src/pages/editor/index.tsx" /* webpackChunkName: "component---src-pages-editor-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-list-index-tsx": () => import("./../../../src/pages/list/index.tsx" /* webpackChunkName: "component---src-pages-list-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-setting-index-tsx": () => import("./../../../src/pages/setting/index.tsx" /* webpackChunkName: "component---src-pages-setting-index-tsx" */),
  "component---src-pages-user-notifications-index-tsx": () => import("./../../../src/pages/user/notifications/index.tsx" /* webpackChunkName: "component---src-pages-user-notifications-index-tsx" */),
  "component---src-pages-user-personal-components-nav-bar-index-tsx": () => import("./../../../src/pages/user/personal/components/NavBar/index.tsx" /* webpackChunkName: "component---src-pages-user-personal-components-nav-bar-index-tsx" */),
  "component---src-pages-user-personal-components-user-info-index-tsx": () => import("./../../../src/pages/user/personal/components/UserInfo/index.tsx" /* webpackChunkName: "component---src-pages-user-personal-components-user-info-index-tsx" */),
  "component---src-pages-user-personal-index-tsx": () => import("./../../../src/pages/user/personal/index.tsx" /* webpackChunkName: "component---src-pages-user-personal-index-tsx" */),
  "component---src-pages-user-setting-account-index-tsx": () => import("./../../../src/pages/user/setting/account/index.tsx" /* webpackChunkName: "component---src-pages-user-setting-account-index-tsx" */),
  "component---src-pages-user-setting-company-index-tsx": () => import("./../../../src/pages/user/setting/company/index.tsx" /* webpackChunkName: "component---src-pages-user-setting-company-index-tsx" */),
  "component---src-pages-user-setting-profile-index-tsx": () => import("./../../../src/pages/user/setting/profile/index.tsx" /* webpackChunkName: "component---src-pages-user-setting-profile-index-tsx" */)
}

