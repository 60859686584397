import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ICategory, ICategoryTree, ITag } from "../../typing/homeTyping"
import { IArticle } from "../../typing/articleTyping";

// 为 slice state 定义一个类型
interface UserState {
  allTags: ITag[];
  atriclesPage: API.IPageData<IArticle> | null;
  allCategory: ICategory[];
  topCategory: ICategory[];
  treeCategory: ICategoryTree[];
  homeCurQuery: {
    keyword: string;
  };
}

const initialState: UserState = {
  allTags: [],
  atriclesPage: null,
  allCategory: [],
  topCategory: [],
  treeCategory: [],
  homeCurQuery: {
    keyword: ''
  },
}

export const homeSlice = createSlice({
  name: 'home',
  // `createSlice` 将从 `initialState` 参数推断 state 类型
  initialState,
  reducers: {
    setAllTags: (state, action: PayloadAction<ITag[]>) => {
      state.allTags = action.payload
    },
    setAtriclesPage: (state, action: PayloadAction<API.IPageData<IArticle>>) => {
      state.atriclesPage = action.payload
    },
    setAllCategory: (state, action: PayloadAction<ICategory[]>) => {
      state.allCategory = action.payload
    },
    setTopCategory: (state, action: PayloadAction<ICategory[]>) => {
      state.topCategory = action.payload
    },
    setTreeCategory: (state, action: PayloadAction<ICategoryTree[]>) => {
      state.treeCategory = action.payload
    },
    setHomeCurQuery: (state, action: PayloadAction<{ keyword: string }>) => {
      state.homeCurQuery = action.payload
    },
  }
})

export const { setAllTags, setAtriclesPage, setAllCategory, setTopCategory, setTreeCategory, setHomeCurQuery } = homeSlice.actions

export default homeSlice.reducer