/**
 * 性别枚举
 */
export enum GENDER {
  /**
   * 男
   */
  MALE = 'MALE',
  /**
   * 女
   */
  FEMALE = 'FEMALE',
  /**
   * 保密
   */
  OTHER = 'OTHER',
}
/**
 * 文章类型
 */
export enum ARTICLE_TYPE {
  /**
   * 文章
   */
  ARTICLE = 'ARTICLE',
  /**
   * 问答
   */
  QUESTION = 'QUESTION',

  ANNOUNCEMENT = 'ANNOUNCEMENT'
}

export enum USER_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  /**
   * 待审批
   */
  PENDING = 'PENDING',
}

/**
 * 反馈类型
 */
export enum FEEDBACK_TYPE {
  /**
   * 文章
   */
  ARTICLE = 'ARTICLE',
  /**
   * 评论
   */
  COMMENT = 'COMMENT'
}

/**
 * 审核类型
 */
export enum AUDIT_STATUS {
  /**
   * 草稿
   */
  DRAFT = 'DRAFT',
  /**
   * 待审核
   */
  PENDING_REVIEW = 'PENDING_REVIEW',
  /**
   * 审核通过
   */
  APPROVED = 'APPROVED',
  /**
   * 审核不通过
   */
  REJECTED = 'REJECTED'
}

export enum COMMENT_STATUS {
  /**
   * 草稿
   */
  DRAFT = 'DRAFT',
  /**
   * 待审核
   */
  PENDING_REVIEW = 'PENDING_REVIEW',
  /**
   * 已采纳
   */
  APPROVED = 'APPROVED',
}

export enum CATEGORY_MODULE {
  /**
   * 萃欧信息
   */
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  /**
   * 知识库
   */
  ARTICLE = 'ARTICLE',
  /**
   * Q&A
   */
  QUESTION = 'QUESTION',
  /**
   * 资讯
   */
  NEWS = 'NEWS'
}

export enum AGREE_TYPE_ENUM {
  /**
   * 文章
   */
  ARTICLE = 'ARTICLE',
  /**
   * 问答
   */
  QUESTION = 'QUESTION',
  /**
   * 萃欧信息
   */
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  /**
   * 评论
   */
  COMMENT = 'COMMENT'
}

export enum ANSWER_NUM_ENUM {
  /**
   * 已关闭
   */
  REJECTED_ANSWER = -1,
  /**
   * 未回答
   */
  NO_ANSWER,
  /**
   * 已回答
   */
  ANSWERED,
}