import { whiteList } from "../config/configuration";
import { getToken } from "./commonUtil";

export const isWhiteList = (url: string): boolean => {
  const urlItem = url.split('/')
  for (const whiteUrl of whiteList) {
    const whiteUrlItem = whiteUrl.split('/')
    for (const index in whiteUrlItem) {
      if (whiteUrlItem[index] === '**') {
        return true
      }
      if (whiteUrlItem[index].startsWith(':') || whiteUrlItem[index] === '*' || whiteUrlItem[index] === urlItem[index]) {
        if (Number(index) === whiteUrlItem.length - 1 && Number(index) == urlItem.length - 1) {
          return true
        } else {
          continue;
        }
      }
      break;
    }
  }
  return false
}

export const isLogin = (): boolean => !!getToken();