import React, { useCallback, useMemo } from "react"
import './index.less'
import { navigate } from "gatsby"
import { useAppSelector } from "../../../hooks/redusHooks";
import { selectUserIsAdmin, selectUserPermission } from "../../../store/selector/user.selector";
import { HEADER_NAV } from "../../../config/configuration";

const PRE_FIX = 'HEADER_NAV'

interface IProps {
  model?: string;
}

const HeaderNav: React.FC<IProps> = ({ model }) => {
  const isAdmin = useAppSelector(selectUserIsAdmin)
  const allPromession = useAppSelector(selectUserPermission)

  const hasPromessionHeaderNav = useMemo(() => HEADER_NAV.filter(item => item.permission === true || allPromession.includes(item.permission || '') || isAdmin), [isAdmin, allPromession])

  const clickHeaderNavHandler = useCallback((key: string) => {
    if (key === 'HOME') {
      // 跳转至主页
      navigate("/", { state: { model: key } })
    } else {
      // 带上参数对应的参数：标签id或分类id
      navigate(`/list?model=${key}`, { state: { model: key } })
    }
  }, [])

  return <div className={`${PRE_FIX}`}>
    {hasPromessionHeaderNav.map(item => <div className={`${PRE_FIX}-nav-item`}><span style={{ color: model === item.key ? '#910012' : '#000' }} onClick={() => clickHeaderNavHandler(item.key)}>{item.name}</span></div>)}
  </div>
}

export default HeaderNav
