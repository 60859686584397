import { permissionMap } from "../../config/permissionContant";
import { RootState } from "../createStore";
import { selectUserIsAdmin } from "./user.selector"

export const canAdminAccess = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.admin.access)
}

export const canAddComments = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.comment.add)
}

export const canEditComments = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.comment.edit)
}

export const canDeleteComments = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.comment.delete)
}

export const canViewCommentsLog = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.article.log)
}

export const canAddArticle = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.article.add)
}

export const canEditArticle = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.article.edit)
}

export const canDeleteArticle = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.article.delete)
}

export const canAuditArticle = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.auditLog.add)
}

export const canViewArticleLog = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.article.log)
}

export const canAddTag = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.tag.add)
}

export const canEditTag = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.tag.edit)
}

export const canDeleteTag = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.tag.delete)
}

export const canAddCategory = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.category.add)
}

export const canEditCategory = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.category.edit)
}

export const canDeleteCategory = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.category.delete)
}

export const canQueryCategory = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.category.query)
}

export const canCategoryMgt = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.category.admin)
}

export const canQueryUser = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.user.query)
}

export const canAddUser = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.user.add)
}

export const canEditUser = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.user.edit)
}

export const canDeleteUser = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.user.delete)
}
/**
 * 修改用户状态权限
 * @param state 
 * @returns 
 */
export const canStatusUser = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.user.status)
}

export const canQueryRole = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.role.query)
}

export const canAddRole = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.role.add)
}

export const canEditRole = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.role.edit)
}

export const canDeleteRole = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.role.delete)
}

export const canConfigurePermission = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.role.permission)
}

export const canAddAnnotations = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.annotations.add)
}

export const canDeleteAnnotations = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.annotations.delete)
}

export const canEditAnnotations = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.annotations.edit)
}

export const canQueryAnnotations = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.annotations.query)
}

export const canAddAgent = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.agent.add)
}

export const canDeleteAgent = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.agent.delete)
}

export const canEditAgent = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.agent.edit)
}

export const canQueryAgent = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.agent.query)
}

export const canAddAnnouncement = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.announcement.add)
}

export const canDeleteAnnouncement = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.announcement.delete)
}

export const canEditAnnouncement = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.announcement.update)
}

export const canQueryAnnouncement = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.announcement.page)
}

export const canAddQuestion = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.question.add)
}

export const canDeleteQuestion = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.question.delete)
}

export const canEditQuestion = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.question.update)
}

export const canQueryQuestion = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.question.page)
}

export const canAddAnswer = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.answer.add)
}

export const canUpdateAnswer = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.answer.update)
}

export const canDeleteAnswer = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.answer.delete)
}

export const canAddNews = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.news.add)
}

export const canDeleteNews = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.news.delete)
}

export const canEditNews = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.news.update)
}

export const canAddCover = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.cover.add)
}

export const canDeleteCover = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.cover.delete)
}

export const canEditCover = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.cover.update)
}

export const canAddCarousel = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.carousel.add)
}

export const canDeleteCarousel = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.carousel.delete)
}

export const canEditCarousel = (state: RootState) => {
  const userPermission = state.userReducer.userPermission
  return selectUserIsAdmin(state) || userPermission.includes(permissionMap.carousel.update)
}