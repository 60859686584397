import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IUserDto } from "../../typing/loginTyping"
import { getCurrentPermission } from "../../util/commonUtil";

// 为 slice state 定义一个类型
interface UserState {
  userBaseInfo: IUserDto | null;
  userPermission: string[];
}

const initialState: UserState = {
  userBaseInfo: null,
  userPermission: getCurrentPermission()
}

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` 将从 `initialState` 参数推断 state 类型
  initialState,
  reducers: {
    setUserBaseInfo: (state, action: PayloadAction<IUserDto | null>) => {
      state.userBaseInfo = action.payload
    },
    setUserPermission: (state, action: PayloadAction<string[]>) => {
      state.userPermission = action.payload
    },
  }
})

export const { setUserBaseInfo, setUserPermission } = userSlice.actions

export default userSlice.reducer