import React, { FC, useCallback, useEffect, useState } from 'react';
import { Accordion, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import './index.css';
import { Link } from 'gatsby';
import { useAppSelector } from '../../../hooks/redusHooks';
import { selectUserIsAdmin, selectUserPermission } from '../../../store/selector/user.selector';

interface IMenu {
  name: string;
  path: string;
}

interface IMenuNodeProps {
  menu: IMenu,
  activeKey: string;
  menuClickHandler: (evt: React.MouseEvent<HTMLElement, MouseEvent>, menu: IMenu) => void;
}

const MenuNode: React.FC<IMenuNodeProps> = ({
  menu,
  activeKey,
  menuClickHandler
}) => {
  return (
    <Nav.Item key={menu.path} className="w-100">
      <Nav.Link
        eventKey={menu.path}
        as="button"
        onClick={(evt) => {
          menuClickHandler(evt, menu);
        }}
        className={classNames(
          'text-nowrap d-flex flex-nowrap align-items-center w-100',
        )}
        style={{
          backgroundColor: activeKey === menu.path ? '#0033FF !important' : undefined,
          color: activeKey === menu.path ? '#FFF !important' : undefined,
        }}>
        <Link
          to={menu.path}
          className="nav-link">
          {menu.name}
        </Link>
      </Nav.Link>
    </Nav.Item>
  );
}

interface AccordionProps {
  menus: any[];
  path: string;
}

function getActiveKeyByPath(path: string) {
  if (path.endsWith('/')) {
    return path.slice(0, -1)
  }
  return path
}

const AccordionNav: FC<AccordionProps> = ({ menus = [], path }) => {
  const isAdmin = useAppSelector(selectUserIsAdmin)
  const allPromession = useAppSelector(selectUserPermission)
  const [activeKey, setActiveKey] = useState<string>(() => getActiveKeyByPath(path))

  useEffect(() => {
    const pathOfActiveKey = getActiveKeyByPath(path)
    setActiveKey(pathOfActiveKey)
  }, [path])


  const menuClickHandler = useCallback((evt: React.MouseEvent<HTMLElement, MouseEvent>, menu: IMenu) => {
    evt.stopPropagation();
    // setActiveKey(menu.path)
  }, [])
  return (
    <Accordion flush>
      <Nav variant="pills" className="flex-column" activeKey={activeKey}>
        {menus
          .filter(menus => menus.permission === true || allPromession.includes(menus.permission) || isAdmin)
          .map((li) => {
            return (
              <MenuNode
                menu={li}
                menuClickHandler={menuClickHandler}
                key={li.path}
                activeKey={activeKey}
              />
            );
          })}
      </Nav>
    </Accordion>
  );
};

export default AccordionNav;
