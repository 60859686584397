import React,  { useState }  from 'react';
import { Container } from 'react-bootstrap';
import record from '../images/record.png'
import { Modal } from 'antd';
import './footer.less';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <footer className="bg-light">
      <Container className="py-3">
        <p className="text-center mb-0 small text-secondary">
          <a href="http://www.taxspace.eu" target="_blank">
            萃欧官网
          </a>
          <br /> 
          Copyright © 2024 ChinEuro
          <a href="javascript:;" style={{marginLeft: 10}} onClick={showModal}>
            版权声明
          </a>
        </p>
        <div className="text-center mb-0 small text-secondary font">
          <a  href='https://beian.miit.gov.cn/' target="_blank">沪ICP备2023030671号-1</a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src={record} width={14} style={{marginBottom:'4px'}}></img>
            &nbsp;
            <a href='https://beian.mps.gov.cn/#/query/webSearch?code=32120202010626' target="_blank">苏公网安备32120202010626</a>
        </div>
        <Modal className="copyright" 
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        footer={(_, { OkBtn }) => (
          <>
            <OkBtn />
          </>
        )}
        width={1000}>
          <h3>《版权声明》</h3>
          <br/>
          <p>萃欧知识库个人账号仅限个人使用，禁止借于他人共享使用。</p>
          <p>由于萃欧知识库上的内容均来源于内部原创，故萃欧知识库拥有此类内容的版权，本站所有资源仅供学习与交流，不得用于任何商业用途的范围，用户应自觉遵守著作权法及其他相关法律的规定，不得侵犯本网站及权利人的合法权利，给萃欧知识库和任何第三方造成损失的，侵权用户应负全部责任。</p>
          <p>萃欧知识库鼓励用户对网站上的内容进行全面监督，当版权拥有者提出侵权指控并出示充分的版权证明材料时，脆皮知识库会在第一时间对投诉举报进行核实，并及时清理侵权作品。</p>
          <p>萃欧知识库赋予每个账号在满足一定条件的情况下，通过网站正式程序月度、评论与分享的资格，但作为网站管理者，有权为保护账号安全，同时给予一定的下载限制，而不因此承担法律责任。</p>
          <p>本站用户所发评论言论仅代表网友自己，不代表本站观点，当用户的言论和行为侵犯了第三方的著作权或其他权利，责任在于用户本人，萃欧知识库不承担任何法律责任。在萃欧知识库发表言论的网友，我们认为用户已经知道并理解这一声明。</p>
          <p>未经萃欧知识库许可，任何人不得盗链萃欧知识库的下载资源；不得复制或仿造本站或者在非萃欧知识库所属服务器上建立镜像，萃欧知识库的标识、源代码、及所有页面的版式设计等，版权归萃欧知识库所有。未经授权，不得用于除萃欧知识库之外的任何站点。萃欧知识库认为，一切用户在进入萃欧知识库主页及各分页时已经仔细看过本协议以上条款并完全同意，敬请谅解！</p>
          <p>如果版权拥有者发现自己作品被侵权，任何单位和个人向萃欧知识库投诉举报时，应提交姓名、电话、身份证明、权属证明、具体链接（URL）及侵权情况详细描述发往版权投诉举报通道，萃欧知识库在收到相关举报文件后，若情况属实，会在24小时内采取删除、屏蔽、断开链接等措施来移除相关涉嫌侵权的内容。</p>
        </Modal>
      </Container>
    </footer>
  );
};

export default React.memo(Footer);
