import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/main.css';
import BaseLayouts from './src/layouts/BaseLayouts'
import store from './src/store/createStore'
import { ConfigProvider } from "antd";

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const wrapPageElement = ({ element, props }) => {
  const isLoginPage = props.path === '/login' || props.path === '/login/' || props.path === '/register' || props.path === '/register/'
  return <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#910012'
      },
    }}
  >
    {isLoginPage ? element : <BaseLayouts {...props}>{element}</BaseLayouts>}
  </ConfigProvider>
}

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}