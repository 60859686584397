import axios from "axios";
import { getToken, logoutClearLocal } from "../util/commonUtil";
import { navigate } from "gatsby";
import { Modal } from "antd";
import { isWhiteList } from "../util/requeseUtil";

const service = axios.create({
  // 设置的基础路径(根路径)，每次发送请求只需要写根路径后面的路径即可，会自动添加根路径
  baseURL: '/api'
})

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    if (!config.url) {
      return Promise.reject(`错误请求: ${config.url}`)
    }
    // 不用token的请求
    if (isWhiteList(config.url)) {
      return config;
    }
    const access_token = getToken();
    // 请求header带上token
    if (access_token) {
      config.headers['Authorization'] = access_token
      return config
    } else {
      // return Promise.reject({
      //   response: {
      //     status: 401
      //   }
      // });
      return config;
    }
  },
  function (error) {
    console.log(error)
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  const dataJson = response.request.responseText
  const data = JSON.parse(dataJson, (key, value) => {
    // js数字超过64位就会失去精度，本来想这边给他转成字符串，但是没用，现在后端直接返回字符串了
    if (typeof value === 'number' && !Number.isSafeInteger(value)) {
      return value + '';
    }
    return value;
  });
  return data;
}, function (error) {
  switch(error?.response?.status) {
    case 401:
      // 取消请求
      logoutClearLocal();
      navigate("/");
      break; 
    case 403:
      // 取消请求
      Modal.error({
        title: 'ERROR',
        content: error?.response?.data.msg || 'Unknown exception',
      });
      break;
    case 400:
    case 500:
    default:
      break;
  }
  return {
    code: error?.response?.data?.code || 500,
    data: null,
    msg: error?.response?.data?.msg || error?.response?.data?.message || error
  }
  // 超出 2xx 范围的状态码都会触发该函数。
  
   // 用Promise.reject(error)会直接throw Error,报错不友好
  //  return Promise.reject(error)
})

/**
 * 在返回拦截器中， 只取了返回值，这边改一下返回类型
 */
const requests: API.IRequest = {
  get: function <T = any, R = API.IRespon<T>, D = any>(url: string, config?: any): Promise<R> {
    return service.get(url, config)
  },
  getPage: function <T = any, R = API.IPageResponse<T>, D = any>(url: string, config?: any): Promise<R> {
    return service.get(url, config)
  },
  delete: function <T = any, R = API.IRespon<T>, D = any>(url: string, config?: any): Promise<R> {
    return service.delete(url, config)
  },
  options: function <T = any, R = API.IRespon<T>, D = any>(url: string, config?: any): Promise<R> {
    return service.options(url, config)
  },
  post: function <T = any, R = API.IRespon<T>, D = any>(url: string, data?: D | undefined, config?: any): Promise<R> {
    return service.post(url, data, config)
  },
  put: function <T = any, R = API.IRespon<T>, D = any>(url: string, data?: D | undefined, config?: any): Promise<R> {
    return service.put(url, data, config)
  }
}

export default requests;