import qs from "qs";
import type { IAuditCommentLogSaveDto, ICarousel, ICarouselQueryDto, ICategoryMgtTree, ICategoryQueryDto, ICategorySaveDto, ICommentMgtAudit, ICommentsByArticleQueryDto, ICover, ICoverQueryDto, IFeedbackQueryDto, IModerator, IModeratorCategoryDto, IModeratorQueryDto, IModeratorSaveDto, INoModeratorUser, IPermission, ITagSaveDto, IUser, IUserQueryDto, IUserSaveDto } from "../typing/mgtTyping";
import requests from "./request";
import { USER_STATUS } from "../typing/enum";
import { IFeedbackPageDto } from "../typing/feedbackTyping";
import { IAgent, IAgentQueryDto, IAgentSaveDto } from "../typing/agentTypeing";
import { IArticleQueryDto, IArticle } from "../typing/articleTyping";
import { ICategory, ITagQueryDto, ITag } from "../typing/homeTyping";

export async function getUserPage(params?: IUserQueryDto) {
  return requests.getPage<IUser>(`/user/page?${qs.stringify(params)}`)
}

export async function addUser(data: IUserSaveDto) {
  return requests.post<IUser>(`/user`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function addInnerUser(data: IUserSaveDto) {
  return requests.post<IUser>(`/user/inner`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function updateUser(data: IUserSaveDto) {
  return requests.put<IUser>(`/user`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function updateUserRole(userId: string, roleId: string) {
  return requests.put<null>(`/user/update/role/${userId}/${roleId}`)
}

export async function updateUserStatus(userId: string, status: USER_STATUS) {
  return requests.put<null>(`/user/update/status/${userId}/${status}`)
}

export async function deleteUser(id: string) {
  return requests.delete<null>(`/user/${id}`)
}

export async function getInnerUser() {
  return requests.get<INoModeratorUser[]>(`/moderator/category/user`)
}

export async function getModeratorPage(params?: IModeratorQueryDto) {
  return requests.getPage<IModerator>(`/moderator/category/list?${qs.stringify(params)}`)
}

export async function addModerator(data: IModeratorSaveDto) {
  return requests.post<IModerator>(`/moderator`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function getCategoryMgtTree() {
  return requests.get<ICategoryMgtTree[]>(`/category/tree/mgt`)
}

export async function getCategoryPage(params?: ICategoryQueryDto) {
  return requests.getPage<ICategory>(`/category/page?${qs.stringify(params)}`)
}

export async function addCategory(data: ICategorySaveDto) {
  return requests.post<ICategory>(`/category`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function updateCategory(data: ICategorySaveDto) {
  return requests.put<ICategory>(`/category`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function deleteCategory(id: string) {
  return requests.delete<null>(`/category/${id}`)
}

export async function getTagPage(params?: ITagQueryDto) {
  return requests.getPage<ITag>(`/tag/page?${qs.stringify(params)}`)
}

export async function addTag(data: ITagSaveDto) {
  return requests.post<ITag>(`/tag`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function updateTag(data: ITagSaveDto) {
  return requests.put<ITag>(`/tag`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function deleteTag(id: string) {
  return requests.delete<null>(`/tag/${id}`)
}

export async function getAllPermission() {
  return requests.get<IPermission[]>(`/permission`)
}

export async function getFeedbackByPage(params?: IFeedbackQueryDto) {
  return requests.getPage<IFeedbackPageDto>(`/feedback/page?${qs.stringify(params)}`)
}

export async function getModeratorCategory(id: string) {
  return requests.get<IModeratorCategoryDto>(`/moderator/category/detail/${id}`)
}

export async function updateModeratorCategory(data: IModeratorCategoryDto) {
  return requests.put<null>(`/moderator/category/update`, data, { 
    headers: {
      'Content-Type': 'application/json',
    },
  })
  
}
// export async function updateModeratorCategory(data: IModeratorCategoryDto) {
//   return requests.put<null>(`/moderator/category`, data, {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   })
// }

export async function deleteModeratorCategory(id: string) {
  return requests.delete<null>(`/moderator/category/${id}`)
}

export async function getCommentsByArticles(params?: ICommentsByArticleQueryDto) {
  return requests.getPage<ICommentMgtAudit>(`/comment/reviewComment?${qs.stringify(params)}`)
}

export async function auditComment(data: IAuditCommentLogSaveDto) {
  return requests.post<null>(`/auditlog/comment`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}


export async function getAtriclesMgtByPage(params: IArticleQueryDto) {
  return requests.getPage<IArticle>(`/article/review?${qs.stringify(params)}`)
}


export async function getAllAgent() {
  return requests.get<IAgent[]>(`/agent`)
}

export async function getAgentPage(params?: IAgentQueryDto) {
  return requests.getPage<IAgent>(`/agent/page?${qs.stringify(params)}`)
}

export async function deleteAgent(id: string) {
  return requests.delete<null>(`/agent/${id}`)
}

export async function addAgent(data: IAgentSaveDto) {
  return requests.post<IUser>(`/agent`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function updateAgent(data: IAgentSaveDto) {
  return requests.put<IUser>(`/agent`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function getCoverPage(params?: ICoverQueryDto) {
  return requests.getPage<ICover>(`/cover/page?${qs.stringify(params)}`)
}

export async function deleteCover(id: string) {
  return requests.delete<null>(`/cover/${id}`)
}

export async function addCover(data: ICover) {
  return requests.post<ICover>(`/cover`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function updateCover(data: ICover) {
  return requests.put<ICover>(`/cover`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function getCarouselPage(params?: ICarouselQueryDto) {
  return requests.getPage<ICarousel>(`/carousel/page?${qs.stringify(params)}`)
}

export async function deleteCarousel(id: string) {
  return requests.delete<null>(`/carousel/${id}`)
}

export async function addCarousel(data: ICarousel) {
  return requests.post<ICarousel>(`/carousel`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function updateCarousel(data: ICarousel) {
  return requests.put<ICarousel>(`/carousel`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function updateCarouselStatus(carouselId: string, status: boolean) {
  return requests.put<null>(`/carousel/${carouselId}/${Number(status)}`)
}

export async function getCarouselShow(params?: ICarouselQueryDto) {
  return requests.get<ICarousel[]>(`/carousel/list?${qs.stringify(params)}`)
}
