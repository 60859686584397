import { permissionMap } from "./permissionContant";

/**
 * 不需要带token的请求
 */
export const whiteList = ['/login', '/password/send/code', '/password/reset', '/user/registerCode/:email', '/user/register', '/login/loginCode/:email'];

export const ADMIN_NAV_MENUS = [
  {
    name: '用户管理',
    path: '/admin/user',
    permission: permissionMap.user.query
  },
  // {
  //   name: '代理商管理',
  //   path: '/admin/agent',
  //   permission: permissionMap.agent.query
  // },
  {
    name: '封面管理',
    path: '/admin/cover',
    permission: permissionMap.cover.delete
  },
  {
    name: '轮播图管理',
    path: '/admin/carousel',
    permission: permissionMap.carousel.delete
  },
  {
    name: '公告管理',
    path: '/admin/announcement',
    permission: permissionMap.announcement.delete
  },
  {
    name: '资讯管理',
    path: '/admin/news',
    permission: permissionMap.news.delete
  },
  {
    name: '文章管理',
    path: '/admin/article',
    permission: permissionMap.article.delete
  },
  {
    name: '问答管理',
    path: '/admin/question',
    permission: permissionMap.question.delete
  },
  // {
  //   name: '回答管理',
  //   path: '/admin/comment'
  // },
  {
    name: '分类管理',
    path: '/admin/category',
    permission: permissionMap.category.admin
  },
  {
    name: '标签管理',
    path: '/admin/tag',
    permission: permissionMap.tag.delete
  },
  // {
  //   name: '客户反馈',
  //   path: '/admin/feedback',
  //   permission: true
  // },
  {
    name: '版主管理',
    path: '/admin/moderator',
    permission: permissionMap.category.admin
  },
  {
    name: '权限管理',
    path: '/admin/permission',
    permission: false
  },
];

export const SELF_MENUS = [
  {
    name: '我的资料',
    path: '/user/setting/profile',
    permission: true
  },
  {
    name: '账号安全',
    path: '/user/setting/account',
    permission: true
  },
  {
    name: '我的公司',
    path: '/user/setting/company',
    permission: true
  },
];

export const ALL_WEB_CONFIG_ANCHOR_MAP = {
  TIME_ZONE: {
    key: 'TIME_ZONE',
    href: '#web-setting-anchor-time-zone',
    title: '时区',
  }
}

export const ALL_WEB_CONFIG_ANCHOR = [
  {
    key: ALL_WEB_CONFIG_ANCHOR_MAP['TIME_ZONE'].key,
    href: ALL_WEB_CONFIG_ANCHOR_MAP['TIME_ZONE'].href,
    title: ALL_WEB_CONFIG_ANCHOR_MAP['TIME_ZONE'].title,
  }
]

export const HEADER_NAV = [
  {
    name: '首页',
    key: 'HOME',
    permission: true,
  },
  {
    name: '萃欧',
    key: 'ANNOUNCEMENT',
    permission: permissionMap.announcement.page,
  },
  {
    name: '资讯',
    key: 'NEWS',
    permission: true,
  },
  {
    name: '知识库',
    key: 'ARTICLE',
    permission: true,
  },
  {
    name: 'Q&A',
    key: 'QUESTION',
    permission: true,
  },
]