import React, { } from 'react';
import './index.less'
import CarouselComponent from '../components/CarouselComponent';
import { CATEGORY_MODULE } from '../../typing/enum';

interface IProps {
  children: React.ReactNode,
  path: string;
  model?: CATEGORY_MODULE | 'HOME';
}

const Home: React.FC<IProps> = ({ path, children, model }) => {

  return (
    <div className="home-page">
      <div className="container page">
        {(path === '/' || path === '/list' || path === '/list/') && <CarouselComponent model={model} />}

        {children}
        {/* <div className="row">
          <div className="col-md-9">
            {children}
          </div>

          <div className="col-md-3" style={{ position: 'static' }}>
            <div className="sidebar" style={{ marginBottom: '10px' }}>

              <p>热门标签</p>

              <Tags getAtricles={getAtriclesPageHandler}/>
            </div>

            <div className="sidebar">

              <Category getAtricles={getAtriclesPageHandler} />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Home;
