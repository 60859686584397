import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IDetailDto } from "../../typing/articleTyping"

// 为 slice state 定义一个类型
interface IArticleState {
  articleDetail: IDetailDto | null
}

const initialState: IArticleState = {
  articleDetail: null
}

export const articleSlice = createSlice({
  name: 'article',
  // `createSlice` 将从 `initialState` 参数推断 state 类型
  initialState,
  reducers: {
    setArticleDetail: (state, action: PayloadAction<IDetailDto>) => {
      state.articleDetail = action.payload
    },
  }
})

export const { setArticleDetail } = articleSlice.actions

export default articleSlice.reducer