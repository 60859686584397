import Cookies from 'js-cookie';
import { marked as originMarked } from 'marked';
import { ALL_TIME_ZONE, CURRENT_PERMISSIONS } from '../config/localStorageKeys';
import { ZoneDate } from '../typing/settingTyping';

export const getToken = () => Cookies.get('access_token') || '';

export const searchParamsToObj = (searchParams: string) => {
  // 创建一个 URLSearchParams 对象
  const params = new URLSearchParams(searchParams);

  // 将 URLSearchParams 对象转换为普通对象
  const queryObject: { [key: string]: any } = {};
  for (const [key, value] of params) {
    queryObject[key] = value;
  }

  return queryObject;
}

/**
 * 退出登录
 * 跳转登录时强制清空
 */
export const logoutClearLocal = () => {
  localStorage.clear();
  Cookies.remove('access_token');
};

export function base64ToSvg(base64: string, svgClassName?: string) {
  try {
    // base64 to svg xml
    const svgxml = atob(base64);

    // svg add class
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgxml, 'image/svg+xml');
    const parseError = doc.querySelector('parsererror');
    const svg = doc.querySelector('svg');
    let str = '';
    if (svg && !parseError) {
      if (svgClassName) {
        svg.setAttribute('class', svgClassName);
      }
      // svg.classList.add('me-2');

      // transform svg to string
      const serializer = new XMLSerializer();
      str = serializer.serializeToString(doc);
    }
    return str;
  } catch (error) {
    return '';
  }
}

/**
 * 字符串模糊匹配
 */
export function fuzzyMatch(pattern: string, text: string) {
  const regex = new RegExp(pattern.split('').join('.*'), 'i');
  return regex.test(text);
}

/**
 * 存储当前角色权限
 */
export const setCurrentPermission = (permissions: string[]) => {
  localStorage.setItem(CURRENT_PERMISSIONS, JSON.stringify(permissions))
};

const isBrowser = typeof window !== "undefined"

/**
 * 获取当前角色权限
 */
export const getCurrentPermission = (): string[] => {
  if (isBrowser) {
    const jsonStr = localStorage.getItem(CURRENT_PERMISSIONS)
    if (jsonStr) {
      return JSON.parse(jsonStr)
    }
  }
  return []
};

function S4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export const guid = () =>
  S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();

/**
 * 存储所有时区选项
 */
export const setAllTimeZoneToLocal = (zoneDates: ZoneDate[]) => {
  localStorage.setItem(ALL_TIME_ZONE, JSON.stringify(zoneDates))
};

/**
 * 获取所有时区选项
 */
export const getAllTimeZoneByLocal = (): ZoneDate[] => {
  if (isBrowser) {
    const jsonStr = localStorage.getItem(ALL_TIME_ZONE)
    if (jsonStr) {
      return JSON.parse(jsonStr)
    }
  }
  return []
};

originMarked.setOptions({
  breaks: true
});

export const marked =  originMarked;

export const base64ToFile = (base64: any, fileName = `${Math.random()}`) => {
  let arr = base64.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${fileName}.${mime.split('/')[1]}`, {
      type: mime,
  })
}